import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import {navigate} from "gatsby"

// import { Link } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

import DrinkCard from '../components/DrinkCard'
import Dropdown from '../components/Dropdown'
import RandomCard from '../components/RandomCard'
import {DrinkData} from '../data/DrinkData.js'
import MenuAppBar from '../components/AppBar'
import Grow from '@material-ui/core/Grow';

import {createMuiTheme} from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';

import {ThemeProvider} from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import SEO from "../components/seo"

// import firebase from 'firebase/app';
import getFirebase from '../utils/firebase';

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

function IndexPage() {
  const firebase = getFirebase();

  // State
  const activeDrink = DrinkData[Math.floor(Math.random() * DrinkData.length)];
  const [alcoholFilter, setAlcoholFilter] = useState('Random');
  const [cardShown, setCardShown] = useState(false);
  const cardLabel = 'You Might Like'
  const [buttonEnabled, setButtonEnabled] = useState(false);
  // const [isSignedIn, setIsSignedIn] = useState(undefined);
  const [activeUser, setActiveUser] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [drinks, setDrinks] = React.useState(null);
  const [userFavourites, setUserFavourites] = React.useState([]);

  useEffect(() => {
    if (!firebase)
      return;
    if (isLoaded)
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          setActiveUser(user);
          const db = firebase.firestore();
          let docRef = db
            .collection("users")
            .doc(user.uid);
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                setFirstName(doc.data()['first'])
                setUserFavourites(doc.data()['favourites'])
                // setIsLoaded(true)
                // console.log("Document data:", doc.data());
              } else {
                // doc.data() will be undefined in this case
                navigate('/signedin')
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
            let drinksRef = db
              .collection("drinks").doc('classics')
            drinksRef
              .get()
              .then(function(doc) {
                if (doc.exists) {
                  setDrinks(doc.data())
                   // console.log(drinks)
                   // console.log(drinks['1'].likedBy.length)
                  setIsLoaded(true);
                } else {
                  navigate('/home')
                }
              })
              .catch(function(error) {
                console.log("Error getting document:", error);
              });
        } else {
          navigate('/')
        }
      });
  });

  // Functions
  // const getFilteredDrink = () => {
  //   let filteredDrinkArray = DrinkData.filter(drink => drink.alcohols.includes(alcoholFilter));
  //   let randomFilteredDrinkIndex = Math.floor(Math.random() * filteredDrinkArray.length);
  //   while (filteredDrinkArray[randomFilteredDrinkIndex] === activeDrink && filteredDrinkArray.length > 1) {
  //     randomFilteredDrinkIndex = Math.floor(Math.random() * filteredDrinkArray.length);
  //   };
  //   let newDrink = filteredDrinkArray[randomFilteredDrinkIndex];
  //   return newDrink;
  // }

  // const getRandomDrink = () => {
  //   let randomDrinkIndex = Math.floor(Math.random() * DrinkData.length);
  //   while (DrinkData[randomDrinkIndex] === activeDrink) {
  //     randomDrinkIndex = Math.floor(Math.random() * DrinkData.length);
  //   };
  //   return DrinkData[randomDrinkIndex];
  // };

  const setNewAlcoholFilter = (value) => {
    let newFilter = value.value;
    setButtonEnabled(true);
    setCardShown(false);
    setAlcoholFilter(newFilter);
  }

  const handleFilteredClick = () => {
    navigate("/classics", {
      state: {
        filter: alcoholFilter.toLowerCase()
      }
    })
    // setActiveDrink(getFilteredDrink);
    // setCardShown(true);
    // setCardLabel('Recently Viewed');
  }

  const handleRandomClick = () => {
    // navigate('/random');
    navigate('/random')
    // setActiveDrink(getRandomDrink);
    // setCardShown(true);
    // setCardLabel('Recently Viewed');
  }

  const handleBackClick = () => {
    setButtonEnabled(false);
    setCardShown(false);
  }

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut();
    navigate('/signedout')
  };

  const getGreeting = () => {
    let now = new Date();
    let hrs = now.getHours();
    let msg = "";

    if (hrs > 0)
      msg = "Good evening"; // After 12am
    if (hrs > 6)
      msg = "Good morning"; // After 6am
    if (hrs > 12)
      msg = "Good afternoon"; // After 12pm
    if (hrs > 17)
      msg = "Good evening"; // After 5pm

    return msg;
  };

  const finalPage = isLoaded
    ? <ThemeProvider theme={theme}>
        <Layout>
          <SEO title='Home'/>
          <div style={{
              // backgroundColor: '#fcfcfc',
              minHeight: '100vh',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            <MenuAppBar handleRandomClick={handleRandomClick} setCardShown={setCardShown} handleSignOut={handleSignOut} activeUser={activeUser}/> {
              !cardShown && <Grow in="in">
                  <Grid style={{
                      backgroundColor: '',
                      minHeight: '100vh',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      padding: '50px'
                    }}>
                    <div style={{
                        backgroundColor: '',
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        paddingTop: '10px'
                      }}>
                      <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                        <Typography style={{
                            fontFamily: 'Work Sans',
                            fontWeight: '600',
                            fontSize: '30px',
                            backgroundColor: '',
                            padding: '30px',
                            // letterSpacing: '-1px',
                            lineHeight: '1',
                            textAlign: 'center'
                          }}>{getGreeting() + ', ' + firstName}</Typography>
                        <Dropdown setAlcoholFilter={setNewAlcoholFilter} style={{
                            // paddingLeft: ''
                          }}/>
                        <div style={{
                            paddingTop: '25px'
                          }}>
                          <Button onClick={handleFilteredClick} size="large" variant='contained' color="secondary" disabled={!buttonEnabled}>Find Me a Drink</Button>
                        </div>
                      </div>
                      <div>
                        <RandomCard onClick={handleRandomClick}/>
                      </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <Typography style={{
                          fontFamily: 'Work Sans',
                          fontWeight: '800',
                          fontSize: '38px',
                          backgroundColor: '',
                          paddingBottom: '20px',
                          lineHeight: 1,
                          textAlign: 'center'
                        }}>{cardLabel}</Typography>
                      <div>
                        <DrinkCard isFavourite={userFavourites
                            ? userFavourites.includes(activeDrink.id)
                            : false} drinkData={activeDrink} likes={drinks
                            ? drinks[activeDrink.id].likedBy.length
                            : 0}/>
                      </div>
                    </div>
                  </Grid>
                </Grow>
            }
            {
              cardShown && <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                    minHeight: '100vh',
                    padding: '80px 0px 30px'
                  }}>
                  <SEO title='Random'/>
                  <Grow in="in">
                    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                        // paddingTop: '1vh'
                      }}>
                      <Typography style={{
                          fontFamily: 'Work Sans',
                          fontWeight: '800',
                          fontSize: '38px',
                          backgroundColor: '',
                          paddingBottom: '20px'
                        }}>Just for You</Typography>
                      <DrinkCard drinkData={activeDrink}/>
                      <Button onClick={handleBackClick} style={{
                          marginTop: '20px'
                        }} size="large" color="primary">Back Home</Button>
                    </Grid>
                  </Grow>
                </Grid>
            }</div>
        </Layout>
      </ThemeProvider>
    : <div><MenuAppBar pageTitle={'Home'}/>
  <SEO title='Loading'/>
      <Grid container="container" direction="row" justify="center" alignItems="center" style={{
          minHeight: '100vh',
          width: '100vw'
        }}><CircularProgress/></Grid>
    </div>

  // Render
  return (finalPage)
}

export default IndexPage
