import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import 'typeface-work-sans';

const useStyles = makeStyles(theme => ({
  card: {
    width: 400,
    margin: 20,
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '85vw',
      margin: '0px 20px'
    }
  },
  media: {
    height: 250
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '15px',
    marginTop: '5px'

  },
  content: {
    margin: '15px 0px 0px',
    display: 'flex',
    justifyContent: 'center'
  },
  headingTypography: {
    paddingBottom: 0,
    paddingTop: 8,
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontSize: 28
  },
  bodyTypography: {
    fontSize: 16
  },
  stretch: {
    display: 'flex',
    alignContent: 'stretch',
    alignItems: 'stretch'
  }
}));

export default function RandomCard(props) {

  const classes = useStyles();

  return (<Card onClick={props.onClick} className={classes.card}>
    <div className={classes.stretch}>
      <CardActionArea>
        <div className={classes.content}>
          <CardContent>
            <Typography className={classes.headingTypography} align='center' utterBottom="utterBottom" variant="h5" component="h2">
              Get a Random Drink
            </Typography>
            <div className={classes.actions}>
              <CardActions>
                <Button size="medium" variant='contained' color="primary">
                  Roll the Dice
                </Button>
              </CardActions>
            </div>
          </CardContent>
        </div>
      </CardActionArea>
    </div>
  </Card>);
}
